import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'

let FormComponents = {}

const style = {
	required: {
		width: 5,
		color: 'red',
		float: 'right'
	}
}

/**
*
* THIS FILE IS CALLED: form_components
*
*/

/**
* Form (basic)
*
* @props - noValidate {bool} - enable/disable parsley form validation. Enabled by default.
*/
// NOTE: this HAS to be a real component, so that the REF's work when calling this
// FormComponents.Form = (props) => // <- WRONG!!!
export const Form = class FormComponentsForm extends React.Component {
	render() {
		return (
			<form data-validate={this.props.noValidate ? '' : 'parsley'} {...this.props}>
				{/* all other child form components you choose */}
				{this.props.children}
			</form>
		)
	}
}

Form.propTypes = {
	children: PropTypes.any,
	noValidate: PropTypes.bool,
}
FormComponents.Form = Form

export const Radio = ({name, value, checked, label, onChange}) =>
	<div>
		<input
			className='checkradio__input'
			type='radio'
			name={name}
			value={value}
			checked={checked}
			id={'checkbox-' + value}
			onChange={onChange}
		/>
		<label
			className='checkradio checkradio--radio'
			htmlFor={'checkbox-' + value}
		>
			{label}
		</label>
	</div>

FormComponents.Radio = Radio
FormComponents.Radio.displayName = 'FormComponentsRadio';
Radio.propTypes = {
	name: PropTypes.string,
	value: PropTypes.any,
	checked: PropTypes.bool,
	label: PropTypes.string,
	onChange: PropTypes.func
}

// USE checked to set the value
export const Checkbox = ({id, help, tab, text, name, value, checked, onChange, extras, disabled = false}) =>
	<div className={`u-box__mb1 ${extras}`}>
		{help !== undefined ?
			<span className='u-text__help'>{help}</span> :
			null
		}
		<input
			type='checkbox'
			className='checkradio__input'
			onChange={(event) => {
				event.persist()
				onChange && onChange(event)
			}}
			tabIndex={tab}
			name={name}
			value={value}
			id={id || ('checkbox-' + value)}
			checked={checked === true || checked === 'true'}
			disabled={disabled}
		/>
		<label
			className='checkradio checkradio--checkbox'
			htmlFor={id || ('checkbox-' + value)}
		>
			{text}
		</label>
	</div>

FormComponents.Checkbox = Checkbox
FormComponents.Checkbox.displayName = 'FormComponentsCheckbox';
Checkbox.propTypes = {
	id: PropTypes.string,
	help: PropTypes.string,
	tab: PropTypes.any,
	text: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.any,
	checked: PropTypes.bool,
	onChange: PropTypes.func,
	disabled: PropTypes.bool
}

/**
* Input
*
* @param {Boolean} autoFocus - True to focus this element when rendered
*/
export const Input = ({
	wrapperClass,
	label,
	tabIndex,
	className,
	help,
	noValidate,
	name,
	type = 'text',
	disabled = false,
	required,
	innerElement,
	onChange,
	...rest
}) => {
	const elementWidth = (innerElement && innerElement.symbol) ? 'small-10 large-10 columns' : 'small-12 large-12 columns'

	return (
		<div className={wrapperClass}>
			{label && <label htmlFor={rest.name}>
				{label}
				{required && <div style={style.required} className='required'>*</div>}
			</label>}
			{help &&
			<span className='u-text__help'>{help}</span>
			}
			<div className='row collapse'>
				<div className={elementWidth}>
					<input
						id={name}
						className={className || 'form__element'}
						tabIndex={Number(tabIndex) || '1'}
						type={type}
						name={name}
						data-parsley-required={noValidate ? false : true}
						disabled={disabled}
						onChange={(event) => {
							event.persist()
							onChange && onChange(event)
						}}
						{...rest}
					// data-parsley-length='[4,6]'
					/>
				</div>

				{(innerElement && innerElement.symbol) && (
					<div className='small-2 large-2 columns'>
						<span className='postfix'>{innerElement.symbol}</span>
					</div>
				)}
			</div>
		</div>
	)
}

FormComponents.Input = Input
FormComponents.Input.displayName = 'FormComponentsInput';
Input.propTypes = {
	name: PropTypes.string.isRequired,
	wrapperClass: PropTypes.string,
	label: PropTypes.string,
	tabIndex: PropTypes.number,
	className: PropTypes.string,
	help: PropTypes.string,
	noValidate: PropTypes.bool,
	type: PropTypes.string,
	disabled: PropTypes.bool,
	required: PropTypes.bool
}

/**
* InputAppendText
*
* @param {Boolean} autoFocus - True to focus this element when rendered
*/
export const InputAppendText = ({
	wrapperClass,
	label,
	tabIndex,
	onChange,
	value,
	autoFocus,
	appendText,
	appendWrapper,
	className,
	help,
	noValidate,
	name,
	placeholder,
	type = 'text',
	disabled = false
}) =>
	<div className={wrapperClass}>
		{label && <label htmlFor={name}>{label}</label>}
		{help !== undefined ?
			<span className='u-text__help'>{help}</span> :
			null
		}
		<div className='form__appendwrapper'>
			<div className={appendWrapper || 'form__append'}>
				<div className='form__appendtext'>{appendText}</div>
			</div>
			<input
				id={name}
				className={className || 'form__element'}
				tabIndex={Number(tabIndex) || '1'}
				type={type}
				onChange={onChange}
				name={name}
				autoFocus={autoFocus}
				value={value}
				placeholder={placeholder}
				data-parsley-required={noValidate ? false : true}
				disabled={disabled}
				// data-parsley-length='[4,6]'
			/>
		</div>
	</div>

FormComponents.InputAppendText = InputAppendText
FormComponents.InputAppendText.displayName = 'FormComponentsInputAppendText';
InputAppendText.propTypes = {
	wrapperClass: PropTypes.string,
	label: PropTypes.string,
	tabIndex: PropTypes.number,
	onChange: PropTypes.func,
	value: PropTypes.any,
	autoFocus: PropTypes.bool,
	className: PropTypes.string,
	help: PropTypes.string,
	noValidate: PropTypes.bool,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	type: PropTypes.string,
	disabled: PropTypes.bool,
	appendText: PropTypes.string,
	appendWrapper: PropTypes.string,
}

/**
*
* Options should look like:
*
* [{
*	value:
*   name:
* }]
*
*/
export const Select = ({
	wrapperClass,
	label,
	placeholder,
	id,
	name,
	options = [],
	tabIndex,
	onChange,
	checked,
	addBlank = true,
	validate = false,
	optionKey,
	disabled = false,
	required
}) =>
	<div className={wrapperClass}>
		<label htmlFor={id}>
			{label}
			{required && <div style={style.required} className='required'>*</div>}
		</label>

		<select
			className='form__element'
			tabIndex={tabIndex}
			name={name}
			id={id}
			value={checked}
			onChange={onChange}
			disabled={disabled}
			data-parsley-required={validate}
			placeholder='test'
		>
			{(placeholder && !checked) &&
				<option selected='selected' disabled>{placeholder}</option>
			}

			{/* if we need a blank value first to force user selection */}
			{addBlank ? <option value=''></option> : null}

			{_.map(options, (item) => {
				let value = item.value === undefined ? (item[optionKey] === undefined ? item : item[optionKey]) : item.value
				return (
					<option
						value={value}
						key={item.key ? item.key : value}
					>
						{item.name === undefined ? (item[optionKey] === undefined ? item : item[optionKey]) : item.name}
					</option>
				)
			})}
		</select>
	</div>

FormComponents.Select = Select
FormComponents.Select.displayName = 'FormComponentsSelect';
Select.propTypes = {
	wrapperClass: PropTypes.string,
	label: PropTypes.string,
	id: PropTypes.string,
	name: PropTypes.string,
	options: PropTypes.array,
	tabIndex: PropTypes.number,
	onChange: PropTypes.func,
	checked: PropTypes.string,
	addBlank: PropTypes.bool,
	validate: PropTypes.bool,
	disabled: PropTypes.bool,
	optionKey: PropTypes.string,
	required: PropTypes.bool
}

export const Textarea = ({
	label,
	name,
	value,
	placeholder,
	callback,
	tabIndex,
	validate = true,
	id,
	rows,
	extraClasses = '',
	onBlur,
	labelClass
}) =>
	<div>
		<label className={labelClass}>{label}</label>
		<textarea
			className={'form__element' + extraClasses}
			data-parsley-required={validate}
			value={value}
			name={name}
			placeholder={placeholder}
			onChange={callback}
			onBlur={onBlur}
			tabIndex={tabIndex}
			id={id}
			rows={rows}
		/>
	</div>

FormComponents.Textarea = Textarea
FormComponents.Textarea.displayName = 'FormComponentsTextarea';
Textarea.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	extraClasses: PropTypes.string,
	callback: PropTypes.func,
	tabIndex: PropTypes.number,
	validate: PropTypes.bool,
	id: PropTypes.string,
	placeholder: PropTypes.string,
	rows: PropTypes.string,
	disabled: PropTypes.bool,
	labelClass: PropTypes.string,
	onBlur: PropTypes.func,
}

export const Dynamic = ({fields, handleChange}) =>
	<div>
		{_.map(fields, (field) => {
			let type = 'unknown'

			if (!field.type) {
				type = 'input'
			}

			// not done:
			if (field.type === 'checkbox') {
				type = 'checkbox'
			}

			return (
				<div key={field.name}>
					{type === 'input' &&
						<FormComponents.Input
							label={field.title}
							name={field.name}
							value={field.value}
							onChange={handleChange}
						/>
					}

					{type === 'unknown' &&
						<div>
							Unknown type: {field.title}
						</div>
					}
				</div>
			)
		})}
	</div>

FormComponents.Dynamic = Dynamic
FormComponents.Dynamic.displayName = 'FormComponentsDynamic';
Dynamic.propTypes = {
	handleChange: PropTypes.func,
	fields: PropTypes.object
}

export default FormComponents
